import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Edit = () => import('./views/Edit')
const Add = () => import('./views/Add')
const Create = () => import('./views/Create')
const Moderation = () => import('./views/Moderation')

const LIST = 'profile:company:list'
const EDIT = 'profile:company:edit'
const ADD = 'profile:company:add:search'
const CREATE = 'profile:company:add:create'
const MODERATION = 'profile:company:moderation'

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'COMPANY_MY_LIST',
        entity: 'company',
        additionalPermissions: ['VIEW'],
      },
    }),
    route('add/search/', Add, ADD, {
      meta: {
        accessConfig: accessPermission([TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'COMPANY_ADD',
        entity: 'company',
        additionalPermissions: ['ADD'],
      },
    }),
    route('add/create/', Create, CREATE, {
      props: {
        default: true,
        prevRoute: 'profile:company:add:search',
      },
      meta: {
        accessConfig: accessPermission([TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'COMPANY_ADD',
        entity: 'company',
        additionalPermissions: ['ADD'],
      },
    }),
    route('moderation/', Moderation, MODERATION, {
      meta: {
        accessConfig: accessPermission([TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'COMPANY_MODERATION',
        entity: 'company',
        additionalPermissions: ['ADD'],
      },
    }),
    route(':id/', Edit, EDIT, {
      props: true,
      meta: {
        accessConfig: accessPermission([TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: '',
        entity: 'company',
        additionalPermissions: ['VIEW'],
        fallbackRouteName: LIST,
      },
    }),
  ]
}
