import { route, prefixRoutes } from '@md/routing'
import { REDIRECT_MAP } from '@md/users/constances'

import { createRoutes as createSettingsRoutes } from './modules/settings/router'
import { createRoutes as createDistributorRoutes } from './modules/distributor/router'
import { createRoutes as createProductRoutes } from './modules/product/router'
import { createRoutes as createCategoryRoutes } from './modules/category/router'
import { createRoutes as createOrderRoutes } from './modules/order/router'
import { createRoutes as createShipmentRoutes } from './modules/shipment/router'
import { createRoutes as createManagerRoutes } from './modules/manager/router'
import { createRoutes as createCompanyRoutes } from './modules/company/router'
import { createRoutes as createWarehouseRoutes } from './modules/warehouse/router'
import { createRoutes as createDashboardRoutes } from './modules/dashboard/router'
import { createRoutes as createPlanRoutes } from './modules/plan/router'

const Profile = () => import('./views/Profile')

export function createRoutes(options) {
  return [
    route('', Profile, null, {
      children: [
        prefixRoutes('settings/', createSettingsRoutes(options)),
        prefixRoutes('distributor/', createDistributorRoutes(options)),
        prefixRoutes('product/', createProductRoutes(options)),
        prefixRoutes('category/', createCategoryRoutes(options)),
        prefixRoutes('order/', createOrderRoutes(options)),
        prefixRoutes('shipment/', createShipmentRoutes(options)),
        prefixRoutes('manager/', createManagerRoutes(options)),
        prefixRoutes('company/', createCompanyRoutes(options)),
        prefixRoutes('warehouse/', createWarehouseRoutes(options)),
        prefixRoutes('dashboard/', createDashboardRoutes(options)),
        prefixRoutes('plan/', createPlanRoutes(options)),
        {
          path: '*',
          redirect: { name: REDIRECT_MAP[window.userRole] },
        },
      ],
    }),
  ]
}
