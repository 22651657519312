<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced.--align_center.--space_sm
    +b.g-cell.g-cols.--auto-xs
      d-control-datepicker(
        v-model='checked[0]',
        :config="{ maxDate: checked[1] }"
        :placeholder="labels.startDate"
        autocomplete="off"
        name='startDate'
        append-icon="icon-date events-none"
        @on-change="debounceFn"
      )
    +b.g-cell
      +b.P.ds-caption.--bold -
    +b.g-cell.g-cols.--auto-xs
      d-control-datepicker(
        v-model='checked[1]',
        :config="{ minDate: checked[0] }"
        :placeholder="labels.endDate"
        autocomplete="off"
        name='endDate'
        append-icon="icon-date events-none"
        @on-change="debounceFn"
      )
</template>

<script>
import { debounce, isEqual } from 'lodash'

export default {
  name: 'DateRangeWidget',

  props: {
    value: {},
    filter: {},
  },

  data() {
    return {
      checked: [null, null],
      debounceFn: () => {},
      labels: {
        startDate: this._('с'),
        endDate: this._('по'),
      },
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nval, oval) {
        this.setValue(nval, oval)
      },
    },
  },

  mounted() {
    const timeout = 200
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.debounceFn = debounce(this.submit, timeout)
  },

  methods: {
    filterValue(val) {
      return (val || []).filter(e => e)
    },

    setValue(nval, oval) {
      const newValue = this.filterValue(nval)
      const oldValue = this.filterValue(oval)

      if (!isEqual(newValue, oldValue)) {
        let value = nval

        if (!nval) {
          value = []
        }

        this.$set(this, 'checked', value)
      }
    },

    submit() {
      const isEmpty = this.checked.every(el => !el)
      if (isEmpty) {
        return
      }
      this.$nextTick(() => {
        const val = this.checked
        this.$emit('input', val)
      })
    },
  },
}
</script>
