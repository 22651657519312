const PRICE_MAP = {
  0: '_from',
  1: '_to',
}
export default {
  computed: {
    preparedValues() {
      const localValues = JSON.parse(JSON.stringify(this.value))
      const valuesKeys = Object.keys(localValues)

      return valuesKeys.reduce((acc, key) => {
        if (key.includes('_from') || key.includes('_to')) {
          Object.keys(PRICE_MAP).forEach(index => {
            if (key.includes(PRICE_MAP[index])) {
              const clearKey = key.replace(PRICE_MAP[index], '')

              if (!acc[clearKey]) {
                acc[clearKey] = []
              }

              const initialValue = localValues[key]
              let val

              if (Array.isArray(localValues[key])) {
                [val] = initialValue
              } else {
                val = initialValue
              }
              acc[clearKey][index] = val
            }
          })
        } else {
          acc[key] = localValues[key]
        }

        return acc
      }, {})
    },
  },
}
