export function collectRoutePermissionCheckers(route) {
  return route.matched.reduce((acc, r) => {
    const checker = r.meta?.accessConfig?.checker;

    if (checker) {
      acc.push([checker, r]);
    }

    return acc;
  }, []);
}

export function routeProhibitionResolver(route) {
  const { meta } = route
  if (meta && meta.accessConfig) {
    const { redirect = false } = meta.accessConfig
    return redirect
  }
  return false
}

export function hasRouteAccess(r) {
  const allowedRoutes = collectRoutePermissionCheckers(r)
  if (allowedRoutes.length || !r.name) {
    return true
  }
  return false
}

export function hasRoutePermission(r) {
  if (r.meta.entity) {
    const entity = window.permissions.entities.find(p => p.entity === r.meta.entity)
    const activePermissions = entity.permissions.filter(p => p.has).map(p => p.name)
    const isAllowed = r.meta.additionalPermissions.some(p => activePermissions.includes(p))

    return isAllowed
  }

  return true
}

export const accessGuard = () => (to, from, next) => {
  const timeout = 100
  setTimeout(() => {
    const hasAccessByRole = hasRouteAccess(to)
    const hasAccessByPermission = hasRoutePermission(to)

    if (hasAccessByRole && hasAccessByPermission) {
      next()
    } else {
      const nextRoute = routeProhibitionResolver(to)

      next(nextRoute)
    }
  }, timeout)
}

export const accessConfig = (checker, redirect = false, options = {}) => ({
  checker, redirect, ...options,
});

export function accessMeta() {
  /* eslint-disable prefer-rest-params */
  return accessConfig.apply(this, arguments);
}
