<template lang="pug">
  include /mixins.pug
  +b.g-row.--align_center.--space_sm.--appearance_nowrap
    +b.g-cell
      +b.color-badge.--size_xs(:style="{ backgroundColor: item.color }")
    +b.g-cell
      +b.SPAN.ds-caption.--size_xxs.line-clamp.--variant_custom-2 {{ item.name }}
    +b.g-cell
      +b.SPAN.ds-caption.--size_xxs.nowrap {{ prettyAmountInAPackage }} {{ item.unit ? item.unit.display : '' }}
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },

  computed: {
    prettyAmountInAPackage() {
      const val = Number(this.item.amountInAPackage)

      return Number.isInteger(val) ? val : this.item.amountInAPackage
    },
  },
}
</script>
