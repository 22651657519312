<template lang="pug">
  include /mixins
  ui-click-outside(:do="hide")
    +b.user-bar(:class="{ 'is-active': isActive }")
      +e.heading(@click.prevent="toggle")
        +e.content
          +e.avatar
            u-avatar(:avatar="user.info.image")
          +e.info
            +e.title
              +b.P.ds-caption.--size_2xs.--weight_bold.--color_black.line-clamp--variant_custom-1 {{ user.info.fullName }}
            +e.subtitle
              +b.P.ds-caption.--size_xs.--relief-3.--weight_medium.--color_relief-15.line-clamp--variant_custom-1 {{ user.info.user ? user.info.user.email : '-' }}
        +e.icon
          +b.I.icon-chevron-down
      +e.dropdown
        +e.links
          template(
            v-for="(item, index) in navigation"
            v-if="item.permissions.includes(user.info.role)"
          )
            user-access-state(
              v-if="item.entity"
              :permissions="[item.additionalPermissions]"
              :entity="item.entity"
              v-slot="{ hasAccess }"
            )
              +e.ROUTER-LINK.link(
                v-if="hasAccess"
                :to="{ name: item.to }"
              ) {{ item.title }}
            +e.ROUTER-LINK.link(
              v-else
              :to="{ name: item.to }"
            ) {{ item.title }}

        ui-modal-trigger(
          url='Modals/Authentication/Logout',
          v-slot="{ openPopup }"
        )
          +e.SPAN.logout(
            @click.prevent="openPopup"
          ) {{ _("Вихід") }}
</template>

<script>
import { mapState } from 'vuex'
import { IS_DESKTOP } from '@utils/constances'
import { TYPES } from '@md/users/constances'

export default {
  name: 'UserBar',

  data() {
    return {
      isActive: false,
      navigation: [
        {
          title: this._('Дистрибьюторы'),
          to: 'profile:distributor:list',
          permissions: [TYPES.MANAGER],
          entity: 'distributor',
          additionalPermissions: 'VIEW',
        },
        {
          title: this._('Плани на сезон'),
          to: 'profile:plan:list',
          permissions: [TYPES.MANAGER],
        },
        {
          title: this._('Каталог товаров'),
          to: 'profile:product:list',
          permissions: [TYPES.MANAGER],
        },
        {
          title: this._('Заявки'),
          to: 'profile:order:list',
          permissions: [TYPES.MANAGER],
        },
        {
          title: this._('Мои менеджеры'),
          to: 'profile:manager:list',
          permissions: [TYPES.DISTRIBUTOR],
          entity: 'distributormanager',
          additionalPermissions: 'VIEW',
        },
        {
          title: this._('План на поставку товара'),
          to: 'profile:season:current:perfomance',
          permissions: [TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER],
          entity: 'plan',
          additionalPermissions: 'VIEW',
        },
        {
          title: this._('Мои заявки'),
          to: 'profile:shipment:list',
          permissions: [TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER],
          entity: 'order',
          additionalPermissions: 'VIEW',
        },
        {
          title: this._('Настройки'),
          to: 'profile:settings',
          permissions: [TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER],
        },
      ],
    }
  },

  computed: {
    ...mapState('users/auth', [
      'user',
    ]),
  },

  methods: {
    toggle() {
      if (!IS_DESKTOP) {
        this.isActive = !this.isActive
      }
    },

    hide() {
      this.isActive = false
    },
  },
}
</script>
