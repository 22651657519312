<template lang='pug'>
  include /mixins
  +b.i-catalog.--variant_default.relative
    slot(name="heading")
    catalog-sidebar(
      :filters="filters"
      @update:filters="changeFilters"
      :pagination="pagination",
      :attributesResource='attributesResource',
      :additionalResource="additionalResource"
      :filterKey="filterKey"
      :label='label'
      :filterName="filterName"
      :defaultFilterParams="defaultFilterParams"
    )
      template(#additional)
        slot(name="additional")
    +e.wrapper
      +b.i-preloader.--variant_1.--absolute(:class="{'is-active': loading}")
        +e.item.--size_md
      slot(name="content")
    ui-limit-pagination(
      v-if="pagination.total && needPagination"
      :pagination='pagination'
      @input='handlePaginate'
    )
</template>

<script>

import CatalogSidebar from './Sidebar'

export default {
  components: {
    CatalogSidebar,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'label',
    'filterName',
    'additionalResource',
    'filterKey',
    'needPagination',
    'defaultFilterParams',
  ],

  created() {
    this.getData()
  },

  methods: {
    getData(orderParams) {
      const { filters } = this.parameters

      if (orderParams && filters && filters.order) {
        this.parameters.filters.order = orderParams.order
      } else if (orderParams) {
        if (orderParams.order) {
          this.parameters.filters = { ...filters, ...orderParams }
        } else {
          delete this.parameters.filters.order
        }
      }
      this.changeFilters(this.parameters)
    },

    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
