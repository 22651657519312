import {
  createResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'get-attributes'

export const FILTER_LIST = prefixAPI('/filters/{preset_key}/{?distributor}', MODEL_PREFIX)
export const FORM_ATTRIBUTES = prefixAPI('/forms/{preset_key}/', MODEL_PREFIX)

export const filterListResource = createResource(FILTER_LIST)
export const formAttributesResource = createResource(FORM_ATTRIBUTES)
