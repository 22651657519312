import ControlsElements from './Controls'
import UiElements from './Ui'
import FiltersElements from './Filters'
import ListElements from './List'
import PaginationElements from './Pagination'
import AuthenticationElements from './Authentication'
import ProfileElements from './Profile/components'

export default function Components(Vue) {
  Vue.use(ControlsElements)
  Vue.use(UiElements)
  Vue.use(FiltersElements)
  Vue.use(ListElements)
  Vue.use(PaginationElements)
  Vue.use(AuthenticationElements)
  Vue.use(ProfileElements)
}
