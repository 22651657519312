// import BaseView from '@components/List/View'
import CabinetView from '@components/List/ViewCabinet'
import { withDefaultProps } from '@aspectus/vue-utils'
import { filterListResource } from '@services/cabinet.service'
import RouterParametersController from './RouterParametersController'

const composerCabinet = (
  filtersUrl,
  listUrl = null,
  controllerName = 'ControllerCabinet',
  filterName = 'CabinetFilter'
) => withDefaultProps({
  listResource: () => listUrl,
  filtersResource: () => filtersUrl,
  controllerName: () => controllerName,
  filterName: () => filterName,
})(CabinetView)

const CabinetListView = composerCabinet(filterListResource)

export default function plugin(Vue) {
  Vue.component('cabinet-list-view', CabinetListView)
  Vue.component('router-parameters-controller', RouterParametersController)
}
