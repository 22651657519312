<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced.--justify_between.--align_end
    +b.g-cell.g-cols.--12-xs.--auto-hd
      +b.g-row.--appearance_spaced.--align_end.--space_lg
        +b.g-cell.g-cols.--12-xs.--4-xl.--3-mac(
          v-for="filter in filters"
          :class="{ 'is-hidden is-visible-xl': 'input' === filter.type }"
        )
          tag(
            v-if="filter.type === 'autocomplete'"
            ref="filterWidget"
            :tag="$options.widgetsMap['autocomplete']"
            :value="preparedValues[filter.name]"
            :filter="{ url: filter.autocompleteSettings.url, placeholder: filter.title, trackBy: filter.autocompleteSettings.valuePath, name: filter.name, field: filter }"
            @input="updateAutocompleteValue(filter, $event)"
          )
          tag(
            v-else
            ref="filterWidget"
            :tag="$options.widgetsMap[filter.type]"
            :value="preparedValues[filter.name]"
            :filter-name="filter.name"
            :filter="filter"
            @input="'datefield' === filter.type ? updateMultipleValues(filter.name, $event) : updateValue(filter.name, $event)"
          )
        slot(name="resetButton")
    +b.g-cell.g-cols.--12-xs.--4-xl.--narrow-hd.is-hidden.is-visible-xl(v-if="$slots.additional")
      slot(name="additional")
</template>

<script>
import UiMixin from '../mixins/UiMixin'
import PrepareValuesMixin from '../mixins/PrepareValuesMixin'

export default {
  props: [
    'value',
    'filters',
    'pagination',
  ],

  mixins: [
    UiMixin,
    PrepareValuesMixin,
  ],

  methods: {
    clearWidgetValue() {
      this.$refs.filterWidget.forEach(widget => {
        if (widget.clearValue) {
          widget.clearValue()
        }
      })
    },
  },
}
</script>
