<template lang="pug">
  include /mixins.pug
  +b.LABEL.d-control-checkbox.--variant_flex(
    @click.prevent="$emit('click')"
  )
    +e.INPUT.element(
      type='checkbox'
      :indeterminate.prop="indeterminate"
      :checked="checked"
    )
    +e.SPAN.label
</template>

<script>
export default {
  props: {
    indeterminate: {},
    checked: {},
  },
}
</script>
