<template lang="pug">
include /mixins.pug
+b.i-doughnut-chart
  +e.content(ref="canvas")
  +e.SPAN.label {{ percent }}%
</template>
<script>
export default {
  props: {
    percent: {
      type: Number,
    },
    lineWidth: {
      type: Number,
    },
    size: {
      type: Number,
    },
    colors: {
      type: Object,
      default: () => ({
        bg: '#D8DCE9',
        main: '#1596D1',
      }),
    },
  },

  data() {
    return {
      radius: null,
      ctx: null,
    }
  },

  watch: {
    percent: {
      immediate: true,
      handler() {
        this.initChart()
      },
    },
  },

  methods: {
    initChart() {
      this.$nextTick(() => {
        const el = this.$refs.canvas

        if (!el) return

        this.prepareChart(el)
        this.drawCircle(this.colors.bg, 1)
        this.drawCircle(this.colors.main, this.percent / 100)
      })
    },

    prepareChart(el) {
      const canvas = document.createElement('canvas')

      this.ctx = canvas.getContext('2d')
      this.ctx.clearRect(0, 0, this.size, this.size)

      canvas.width = this.size
      canvas.height = this.size

      el.innerHTML = ''
      el.appendChild(canvas)

      this.ctx.translate(this.size / 2, this.size / 2)
      this.ctx.rotate((-1 / 2 + 0 / this.size) * Math.PI)

      this.radius = (this.size - this.lineWidth - 8) / 2
    },

    drawCircle(color, percent) {
      const calculatedPercent = Math.min(Math.max(0, percent), 1)

      this.ctx.beginPath()

      this.ctx.arc(0, 0, this.radius, 0, Math.PI * 2 * calculatedPercent, false)
      this.ctx.strokeStyle = color
      this.ctx.lineCap = 'round'
      this.ctx.lineWidth = this.lineWidth
      this.ctx.stroke()
    },
  },
}
</script>
