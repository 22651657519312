<template lang="pug">
  include /mixins.pug
  ui-click-outside(:do="clickOutside")
    +b.excel-field(
      @click="renderInput"
      :class="{ 'is-disabled': disabled }"
    )
      +e.wrapper(:class="{ 'is-active': isActive }")
        +e.INPUT.input(
          v-if="isActive"
          v-model.number="localValue"
          ref="field"
          type="tel"
          @keydown.esc="resetValue"
          @keydown.enter="clickOutside"
        )
        +e.SPAN.text(v-else-if="Number(localValue) > 0") {{ Number(localValue) | formatDecimal | prettifyNumber }}
</template>

<script>
import { isDivisable } from '@utils/numbers'

export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Number,
      default: 1,
    },
    maxValue: {
      type: Number,
      default: 1000000,
    },
  },

  data() {
    return {
      localValue: '',
      isActive: false,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(nval) {
        this.localValue = nval || null
      },
    },
  },

  methods: {
    renderInput() {
      if (!this.disabled) {
        this.isActive = true

        this.$nextTick(() => {
          this.setFocus()
        })
      }
    },

    setFocus() {
      this.$refs.field.focus()
    },

    hideInput() {
      this.isActive = false
    },

    clickOutside() {
      if (!this.disabled) {
        this.validateValue()
        this.hideInput()
      }
    },

    resetValue() {
      this.hideInput()

      this.setValue(this.value)
    },

    setValue(val) {
      if (val) {
        const preparedValue = Number(val)
        const numberWithPoint = parseFloat(val).toFixed(2)

        this.localValue = Number.isInteger(preparedValue) ? preparedValue : numberWithPoint
      } else {
        this.localValue = ''
      }
    },

    validateValue() {
      const divisable = isDivisable(this.localValue, this.divider)

      if (divisable) {
        if (this.isActive) {
          if (0 > this.localValue) {
            this.showMinValueErrorNofification()

            this.setValue(this.value)
          } else if (this.localValue <= this.maxValue) {
            this.setValue(this.localValue)

            this.emitValue()
          } else if (this.localValue > this.maxValue) {
            this.setValue(this.maxValue)

            this.emitValue()
          }
        }
      } else {
        this.showDividerErrorNofification()

        this.setValue(this.value)
      }
    },

    showDividerErrorNofification() {
      this.$notify({
        title: this._('Неможливо ввести це значення'),
        text: `${this._('Значення має бути кратне числу')} ${this.divider}`,
        type: 'error',
      })
    },

    // showMaxValueErrorNofification() {
    //   this.$notify({
    //     title: `${this._('Неможливо ввести значення більше за')} ${this.maxValue}`,
    //     type: 'error',
    //   })
    // },

    showMinValueErrorNofification() {
      this.$notify({
        title: this._('Неможливо ввести значення менше за 0'),
        type: 'error',
      })
    },

    emitValue() {
      if (this.value !== this.localValue) {
        this.$emit('input', this.localValue)
      }
    },
  },
}
</script>
