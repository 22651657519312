<template lang="pug">
  include /mixins
  d-control-input(
    @input='searchDebounceFn'
    @keypress.13.prevent='searchDebounceFn'
    :name="filter.name || 'search'"
    :placeholder="filter.title || _('Введите запрос')",
    v-model='query'
    prepend-icon="icon-search"
    autocomplete='off'
    label='caption'
    type='search'
  )
</template>
<script>
import { isEmpty } from '@aspectus/vue-utils'
import { debounce } from 'lodash'

const MIN_LENGTH = 3

export default {
  name: 'SearchWidget',

  props: {
    value: {},
    filter: {
      default: () => {},
    },
  },

  data() {
    return {
      query: '',
      searchDebounceFn: null,
    }
  },

  watch: {
    value(nval) {
      this.query = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.query = this.normalizeFrom(this.value)

    const timeout = 500
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.searchDebounceFn = debounce(this.submit, timeout)
  },

  methods: {
    submit() {
      const length = this.filter['min-length'] || MIN_LENGTH
      const zero = 0
      if (this.query.length >= length) {
        this.$emit('input', this.query)
        return
      }
      if (this.query.length === zero) {
        this.$emit('input', '')
      }
    },

    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      const query = value
      return query
    },

    clearValue() {
      this.query = ''
    },
  },
}
</script>
