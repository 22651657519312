// import CheckboxWidget from './Widgets/CheckboxWidget'
// import CheckboxWidgetSingle from './Widgets/CheckboxWidgetSingle'
// import BooleanWidget from './Widgets/BooleanWidget'
import RangeWidget from './Widgets/RangeWidget'
import RadioWidget from './Widgets/RadioWidget'
import SelectWidget from './Widgets/SelectWidget'
import MultiselectWidget from './Widgets/MultiselectWidget'
import AutocompleteMultiselectWidget from './Widgets/AutocompleteMultiselectWidget'
import ColorWidget from './Widgets/ColorWidget'
import SearchWidget from './Widgets/SearchWidget'
import DateRangeWidget from './Widgets/DateRangeWidget'
import TreeSelectWidget from './Widgets/TreeSelectWidget'
import BooleanSelectWidget from './Widgets/BooleanSelectWidget'

export const WIDGETS_MAP = {
  // checkbox: CheckboxWidget,
  // 'checkbox-single': CheckboxWidgetSingle,
  // BooleanWidget,
  range: RangeWidget,
  radio: RadioWidget,
  color: ColorWidget,
  select: SelectWidget,
  datefield: DateRangeWidget,
  input: SearchWidget,
  checkbox: MultiselectWidget,
  autocomplete: AutocompleteMultiselectWidget,
  'multi-select': MultiselectWidget,
  'tree-select': TreeSelectWidget,
  boolean: BooleanSelectWidget,
}
