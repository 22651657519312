<template lang="pug">
  include /mixins
  +b.ds-panel.--space_2xl
    +e.element.--offset_top
      range-slider(
        class="vue-slider--variant_default"
        v-model="internal"
        :clickable="false"
        :drag-on-click="true"
        transition="0.2"
        :dot-size="12"
        :height="4"
        :tooltip="'always'"
        :min="min"
        :max="max"
        :contained="true"
        :interval="interval"
        @drag-end="update"
      )
      +b.ds-panel.--space_sm
        +e.element.--offset_top
          +b.g-row.--appearance_spaced.--justify_between.--align_center
            +b.g-cell.g-cols
              +b.P.ds-caption.--size_xs.--color_grey {{ min|prettifyPrice }}
            +b.g-cell.g-cols
              +b.P.ds-caption.--size_xs.--color_grey {{ max|prettifyPrice }}
</template>

<script>
/* eslint-disable */
const gcd = (a, b, onZero = 1) => (
  a < b
    ? gcd(b, a, onZero)
    : (
      b < 0.001 || isNaN(b) || isNaN(b)
        ? (0 === b ? onZero : a)
        : gcd(b, a - Math.floor(a / b) * b)
    )
)

const decimalCount = number => {
  const numberAsString = number.toString()
  if (numberAsString.includes('.')) {
    return numberAsString.split('.')[1].length
  }
  return 0
}

export default {
  name: 'range-widget',

  props: ['value', 'filter'],

  data() {
    return {
      internal: [0, 1],
      interval: 1,
      min: 0,
      max: 1,
    }
  },

  watch: {
    filter: { deep: true, immediate: true, handler: 'clearMinMax' },
    value: { immediate: true, handler: 'updateInternal' },
  },

  methods: {
    clearMinMax() {
      const { price_from, price_to } = this.filter.items

      this.min = parseFloat(Math.max(price_from || 0).toFixed(2))
      this.max = parseFloat(Math.min(price_to || 0).toFixed(2))

      const isInteger = Number.isInteger(this.min) && Number.isInteger(this.max)

      this.interval = parseFloat(gcd(Math.abs(this.min), Math.abs(this.max)).toFixed(2))

      if (this.interval === 1 && !isInteger) {
        const minDecimal = decimalCount(this.min)
        const maxDecimal = decimalCount(this.max)
        const exponent = minDecimal > maxDecimal ? minDecimal : maxDecimal
        const d = 1 / Math.pow(10, exponent)
        this.interval = parseFloat((d).toFixed(exponent))
      }

      this.updateInternal()
    },

    updateInternal(value = this.value) {
      this.internal = this.normalize(value)
    },

    update() {
      const value = this.clear(this.internal)
      this.input(value)
    },

    input(value) {
      this.$emit('input', value)
    },

    clear(value) {
      let [min, max] = value
      const [minVal, maxVal] = this.normalize([min, max])
      min = minVal
      max = maxVal
      if (min === this.min && max === this.max) {
        return []
      }

      return [min, max]
    },

    normalize(value) {
      if (!value) {
        return [this.min, this.max]
      }

      const [min, max] = value

      return [min || this.min, max || this.max]
    },
  },
};

</script>
