import { createStore as createUsersStore } from '@md/users/store'
import { createStore as createProfileStore } from '@md/profile/store'

export function createStore(parameters) {
  return {
    modules: {
      users: createUsersStore(parameters),
      profile: createProfileStore(parameters),
    },
  }
}

export function createStoreObject({ Store, Vue }) {
  return new Store(createStore({ Store, Vue }))
}
