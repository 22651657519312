<template lang="pug">
  include /mixins.pug
  +b.g-row.--appearance_spaced.--space_sm
    +b.g-cell.g-cols.--12-xs.--narrow-xl(v-for="item in items")
      +b.i-currency
        +e.SPAN.text {{ item.FROM.amount }} {{ item.FROM.symbol }} = {{ (item.FROM.amount * item.RATE).toFixed(2) }} {{ item.TO.symbol }}
</template>

<script>
export default {
  data() {
    return {
      items: [],
    }
  },

  created() {
    this.prepareItems()
  },

  methods: {
    prepareItems() {
      const rates = window.currenciesRates

      this.items = rates.reduce((acc, el) => {
        acc.push(...el.EXCHANGE_FROM)

        return acc
      }, [])
    },
  },
}
</script>
