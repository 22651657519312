import {
  createResource,
  patchResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'profile'

export const PROFILE_RETRIEVE = prefixAPI('/', MODEL_PREFIX)
export const PROFILE_UPDATE = prefixAPI('/update/', MODEL_PREFIX)

export const profileRetrieveResource = createResource(PROFILE_RETRIEVE)
export const profileUpdateResource = createResource(PROFILE_UPDATE, patchResource)
