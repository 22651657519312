/* eslint-disable */
export function isDivisable(n, d) {
  const num = n || ''
  const ndI = 1 + ''.indexOf.call(num, '.') //Index of the Number's Dot
  const ddI = 1 + ''.indexOf.call(d, '.') // Index of the Divisors Dot

  if (ndI || ddI) { // IF its a float
    const l = Math.max(('' + num).length - ndI, ('' + d).length - ddI) //Longest Decimal Part
    const tmpN = (num * Math.pow(10, l)) //scale the float
    const tmpD = (d * Math.pow(10, l))

    return !~((tmpN % tmpD) - 1) //Substract one of the modulo result, apply a bitwise NOT and cast a boolean.
  }

  return !~((n % d) - 1) // If it isnt a decimal, return the result 
}
