import Avatar from './Avatar'
import Bar from './Bar'
import Currencies from './Currencies'
import AvatarUploader from './AvatarUploader'
import HiJack from './HiJack'
import DoughnutChart from './DoughnutChart'
import TableScroller from './TableScroller'
import TableStickyHeader from './TableStickyHeader'
import ExcelField from './ExcelField'
import Product from './Product'
import SortingField from './SortingField'
import TableRowHighlight from './TableRowHighlight'
import PlanSelector from './PlanSelector'

export default function install(Vue) {
  Vue.component('u-avatar', Avatar)
  Vue.component('u-bar', Bar)
  Vue.component('u-currencies', Currencies)
  Vue.component('u-avatar-uploader', AvatarUploader)
  Vue.component('u-hi-jack', HiJack)
  Vue.component('u-doughnut-chart', DoughnutChart)
  Vue.component('u-table-scroller', TableScroller)
  Vue.component('u-table-sticky-header', TableStickyHeader)
  Vue.component('u-excel-field', ExcelField)
  Vue.component('u-product', Product)
  Vue.component('u-sorting-field', SortingField)
  Vue.component('u-table-row-highlight', TableRowHighlight)
  Vue.component('u-plan-selector', PlanSelector)
}
