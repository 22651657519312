<template lang='pug'>
  include /mixins
  +b.i-filter(v-if="filters.length || $slots.additional")
    +e.additional-wrapper
      +e.additional(v-if="$slots.additional")
        slot(name="additional")
      +e.search(v-if="searchFilter")
        tag(
          :tag="$options.widgetsMap['input']"
          :value="value[searchFilter.name]"
          :filter-name="searchFilter.name"
          :filter="searchFilter"
          @input="updateValue(searchFilter.name, $event)"
        )
    +e.button(v-if="filters.length > 1 || (filters.length === 1 && !searchFilter)")
      +b.g-row
        +b.g-cell.g-cols.--auto
          +b.i-filter-button(@click.prevent="openFilter")
            +e.icon.--position_left.--size_1
              +b.I.icon-filter
            +e.SPAN.text {{ _("Фільтр") }}
            +e.icon.--position_right.--size_2
              +b.I.icon-chevron-right
        +b.g-cell.g-cols.--narrow
          +b.control-button.--space_2(@click.prevent="handleInputFilter({})")
            +e.element
              +e.icon.--space_none
                +b.I.icon-clear-filter
    +e.view(:class="{'is-active': isActive}")
      +b.blur.--variant_1(
        @click.prevent="hideFilter"
        :class="{'is-hidden': !isActive}"
      )
      +e.wrapper
        +e.heading
          +b.P.ds-caption.--size_lg.--color_dark.--weight_bold {{ _("Фільтр") }}
          +b.close(@click.prevent="hideFilter")
            +b.I.icon-close
        +e.main
          +b.i-preloader.--variant_1.--absolute(:class="{'is-active': loading}")
            +e.item.--size_md
          filter-ui(
            ref="filterUi"
            :value="prepared"
            :filters="filters"
            :pagination="pagination"
            @input="handleInputFilter"
          )
            template(#additional)
              slot(name="additional")
            template(#resetButton)
              +b.g-cell.g-cols--narrow.is-hidden.is-visible-xl
                +b.control-button(@click.prevent="handleInputFilter({})")
                  +e.element
                    +e.icon
                      +b.I.icon-clear-filter
                    +e.SPAN.text {{ _('Очистить фильтр') }}
        +e.footer
          +b.BUTTON.control-button(@click.prevent="hideFilter")
            +e.element
              +e.SPAN.text {{ _("Застосувати") }}
</template>

<script>
import ControllerMixin from './mixins/ControllerMixin'
import { WIDGETS_MAP } from '../widgets'

export default {
  widgetsMap: WIDGETS_MAP,

  mixins: [ControllerMixin],
}
</script>
