<template lang="pug">
  include /mixins
  div
    +b.ds-panel.--space_lg(v-if="choicesLocal.length > 20")
      +e.element.--offset_bottom
        d-control-input(
          v-model='searchValue',
          @input="searchAttrs"
          type="text"
          :placeholder="_('Поиск')"
        )
    +b.g-row.--appearance_spaced
      +b.g-cell.g-cols--12-xs(v-for='choice in choicesLocal')
        +b.LABEL.d-control-radio.--variant_flex
          +e.INPUT.element(
            :name="filter.label"
            type='radio'
            :value="choice[1]"
            v-model='checked'
            @change='submit'
          )
          +e.label
          +e.SPAN.content {{ choice[0] }}
</template>
<script>
export default {
  name: 'RadioWidget',

  props: ['value', 'filter'],

  data() {
    return {
      checked: [],
      searchValue: null,
      choicesLocal: [],
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },

    filter: {
      deep: true,
      immediate: true,
      handler(nval) {
        if (nval && nval.values) {
          this.choicesLocal = nval.values
        }
      },
    },
  },

  mounted() {
    this.checked = this.normalize(this.value)
  },

  methods: {
    submit() {
      this.$emit('input', this.checked)
    },

    normalize(value) {
      let val = value
      if (!value) return []
      if (!Array.isArray(value)) {
        val = [value]
      }
      const checked = this.filter.values
        .filter(el => val.find(v => Number(v) === el[1])).map(el => el[1])
      return checked
    },

    searchAttrs() {
      this.$nextTick(() => {
        const choices = JSON.parse(JSON.stringify(this.filter.values))
        this.choicesLocal = choices.filter(choice => {
          const searchVal = this.searchValue.toLowerCase()
          const choiceName = choice.values[0].toLowerCase()
          if (choiceName.includes(searchVal)) {
            return choice
          }
          return false
        })
      })
    },
  },
}
</script>
