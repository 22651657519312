import { mapActions } from 'vuex'
import { createI18n } from '@/i18n'
import { createRouter } from './router'
import { createStoreObject } from './store'

export function createApp({ Vue, I18n, Router, Store }) {
  const i18n = createI18n({ Vue, I18n })
  const store = createStoreObject({ Vue, Store })
  const router = createRouter({ Vue, Router, store })

  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],

    data() {
      return {
        fromRouteName: {
          value: null,
        },
      }
    },

    provide() {
      return {
        fromRouteName: this.fromRouteName,
      }
    },

    watch: {
      $route(to, from) {
        this.fromRouteName.value = from.name
      },
    },

    async created() {
      if (window.isAuthenticated) {
        this.initialReceive()
      }
    },

    methods: {
      ...mapActions('users/auth', [
        'initialReceive',
      ]),
    },
  })

  return { app, i18n, router, store }
}
