import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')

const LIST = 'profile:category:list'

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      entity: 'product',
      additionalPermissions: ['VIEW'],
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'CATEGORY_LIST',
      },
    }),
  ]
}
