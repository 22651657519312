<script>
import { getCookie } from '@utils/cookies'
import { HIJACK_LINK } from '@services/hijack.service'
import { REDIRECT_MAP } from '@md/users/constances'

export default {
  methods: {
    loginAsAnotherUser(id) {
      const formdata = new FormData()
      const route = { name: REDIRECT_MAP[window.userRole] }
      const next = this.$router.resolve(route).href

      formdata.append('user_pk', id)
      formdata.append('csrfmiddlewaretoken', getCookie('csrftoken'))
      formdata.append('next', next)

      const params = {
        method: 'POST',
        body: formdata,
      }

      fetch(HIJACK_LINK, params).then(() => {
        window.location = next
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      loginAsAnotherUser: this.loginAsAnotherUser,
    })
  },
}
</script>
