import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Create = () => import('./views/Create')
const Display = () => import('./views/Display')

const LIST = 'profile:manager:list'
const CREATE = 'profile:manager:create'
const DISPLAY = 'profile:manager:edit'

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'MANAGER_MY_LIST',
        entity: 'distributormanager',
        additionalPermissions: ['VIEW'],
      },
    }),
    route('create/', Create, CREATE, {
      meta: {
        accessConfig: accessPermission([TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'MANAGER_ADD',
        entity: 'distributormanager',
        additionalPermissions: ['ADD'],
        fallbackRouteName: LIST,
      },
    }),
    route(':id/', Display, DISPLAY, {
      props: true,
      meta: {
        accessConfig: accessPermission([TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'MANAGER_EDIT',
        entity: 'distributormanager',
        additionalPermissions: ['CHANGE'],
        fallbackRouteName: LIST,
      },
    }),
  ]
}
