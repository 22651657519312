<template>
  <select-widget
    v-bind="$attrs"
    v-on="$listeners"
    :multiple="multiple"
    :close-on-select="false"
  />
</template>

<script>

import SelectWidget from './SelectWidget'

export default {
  name: 'filter-tree-select-widget',
  components: { SelectWidget },

  props: {
    multiple: { type: Boolean, default: true },
  },
}

</script>
