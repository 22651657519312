export const TYPES = {
  MANAGER: 'M1',
  DISTRIBUTOR: 'A1',
  DISTRIBUTOR_MANAGER: 'A2',
}

export const USER_TYPES = {
  [TYPES.MANAGER]: { isManager: true },
  [TYPES.DISTRIBUTOR]: { isDistributor: true },
  [TYPES.DISTRIBUTOR_MANAGER]: { isDistributorManager: true },
}

export const REDIRECT_MAP = {
  [TYPES.MANAGER]: 'profile:distributor:list',
  [TYPES.DISTRIBUTOR]: 'profile:dashboard',
  [TYPES.DISTRIBUTOR_MANAGER]: 'profile:settings',
}
