import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Create = () => import('./views/Create')
const Success = () => import('./views/Success')

const LIST = 'profile:shipment:list'
const CREATE = 'profile:shipment:create'
const SUCCESS = 'profile:shipment:success'

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'SHIPMENT_LIST',
      },
    }),
    route('create/', Create, CREATE, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'SHIPMENT_CREATE',
      },
    }),
    route('success/', Success, SUCCESS, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'SHIPMENT_SUCCESS',
      },
    }),
  ]
}
