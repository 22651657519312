import { accessMeta } from '@md/permissions'
import { REDIRECT_MAP } from '@md/users/constances'

const { userRole } = window
const initialRoute = { name: REDIRECT_MAP[userRole] }

export const permissionChecker = permissions => permissions.includes(userRole)

export const accessPermission = (permissions, options) => accessMeta(
  permissionChecker(permissions), initialRoute, options
)
