<template lang="pug">
  include /mixins.pug
  +b.i-table-scroller
    +e.wrapper
      +e.arrow-wrapper
        +e.arrow.--variant_left(
          v-if="scrollIsAvailable"
          @click.prevent="scrollTable(-1)"
        )
          +b.i-arrow.--variant_1
            +b.I.icon-chevron-left
        +e.arrow.--variant_right(
          v-if="scrollIsAvailable"
          @click.prevent="scrollTable(1)"
        )
          +b.i-arrow.--variant_1
            +b.I.icon-chevron-right
      +e.content(
        ref="content"
        @scroll="calculateTableScrollShift"
      )
        slot
    +e.slider(:class="{ 'is-disabled': !scrollIsAvailable }")
      +b.i-scroll-slider
        +e.content
        +e.handle(
          ref="handle"
          :style="handleStyles"
          @mousedown="handleDragStart"
          @touchstart="handleDragStart"
          @dragend="handleDragEnd"
        )
          +e.arrow
            +b.I.icon-chevron-left
          +e.arrow
            +b.I.icon-chevron-right
</template>

<script>
const hundredPercent = 100
const zero = 0

export default {
  props: {
    tableStickyHeaderElement: {
      type: Object,
    },
  },

  data() {
    return {
      delta: 424,
      handleTranslateX: 0,
      maxTranslateX: 114,
      drag: false,
      startPosition: 0,
      currentPosition: 0,
      shiftPercent: 0,
      scrollIsAvailable: false,
    }
  },

  computed: {
    handleStyles() {
      return {
        transform: `translateX(${this.handleTranslateX}px)`,
      }
    },
  },

  beforeUnmount() {
    document.removeEventListener('mouseup', this.handleDragEnd)
    document.removeEventListener('mousemove', this.handleDrag)
    document.removeEventListener('touchend', this.handleDragEnd)
    document.removeEventListener('touchmove', this.handleDrag)
    window.removeEventListener('resize', this.resizeHandler)
  },

  mounted() {
    document.addEventListener('mouseup', this.handleDragEnd)
    document.addEventListener('mousemove', this.handleDrag)
    document.addEventListener('touchend', this.handleDragEnd)
    document.addEventListener('touchmove', this.handleDrag)
    window.addEventListener('resize', this.resizeHandler)

    this.checkScrollAvailability()
  },

  methods: {
    resizeHandler() {
      this.checkScrollAvailability()
    },

    checkScrollAvailability() {
      const contentWrapper = this.$refs.content

      if (!contentWrapper) {
        this.handleTranslateX = 0

        return
      }

      const { clientWidth, scrollWidth } = contentWrapper
      const diff = scrollWidth - clientWidth

      this.scrollIsAvailable = Boolean(diff)

      if (!this.scrollIsAvailable) {
        this.handleTranslateX = 0
      }
    },

    scrollTable(direction) {
      const contentWrapper = this.$refs.content
      const offset = direction * this.delta
      const { scrollLeft } = contentWrapper
      const scrollOffset = offset + scrollLeft

      contentWrapper.scrollTo({ left: scrollOffset, behavior: 'smooth' })
    },

    calculateTableScrollShift() {
      const contentWrapper = this.$refs.content

      if (!contentWrapper) return

      const { clientWidth, scrollWidth, scrollLeft } = contentWrapper
      const diff = scrollWidth - clientWidth
      const shift = scrollLeft / diff * hundredPercent

      if (hundredPercent < shift) {
        this.shiftPercent = hundredPercent
      } else if (zero > shift) {
        this.shiftPercent = zero
      } else {
        this.shiftPercent = shift
      }

      this.updateHandleScrollShift()

      if (this.tableStickyHeaderElement) {
        this.tableStickyHeaderElement.calcTableHeadTopOffset()
      }
    },

    updateHandleScrollShift() {
      this.handleTranslateX = this.maxTranslateX / hundredPercent * this.shiftPercent
    },

    getClientX(event) {
      if (event.clientX) {
        return event.clientX
      }

      const [firstTouch] = event.touches || event.originalEvent.touches

      if (firstTouch) {
        return firstTouch.clientX
      }

      return zero
    },

    handleDrag(event) {
      if (this.drag) {
        const clientX = this.getClientX(event)

        this.currentPosition = clientX

        this.updateHandleLocation()
      }
    },

    handleDragStart(event) {
      this.drag = true

      const clientX = this.getClientX(event)

      this.startPosition = clientX - this.handleTranslateX
      this.currentPosition = clientX

      this.updateHandleLocation()
    },

    handleDragEnd(event) {
      this.drag = false

      const clientX = this.getClientX(event)

      this.startPosition = clientX
      this.currentPosition = clientX
    },

    updateHandleLocation() {
      const translateX = this.currentPosition - this.startPosition

      if (translateX > this.maxTranslateX) {
        this.handleTranslateX = this.maxTranslateX
      } else if (zero > translateX) {
        this.handleTranslateX = zero
      } else {
        this.handleTranslateX = translateX
      }

      this.shiftPercent = this.handleTranslateX / this.maxTranslateX * hundredPercent

      this.updateTableScrollShift()
    },

    updateTableScrollShift() {
      const contentWrapper = this.$refs.content
      const { clientWidth, scrollWidth } = contentWrapper
      const diff = scrollWidth - clientWidth
      const offset = diff / hundredPercent * this.shiftPercent

      contentWrapper.scrollTo({ left: offset })
    },
  },
}
</script>
