import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Detail = () => import('./views/Detail')
const Matrix = () => import('./views/Matrix')
const VolumeDistribution = () => import('./views/VolumeDistribution')
const VolumeShare = () => import('./views/VolumeShare')
const VolumeRequest = () => import('./views/VolumeRequest')
const ShareStatistics = () => import('./views/ShareStatistics')

const LIST = 'profile:plan:list'
const DETAIL = 'profile:plan:detail'
const MATRIX = 'profile:plan:matrix'
const VOLUME_DISTIBUTION = 'profile:plan:volume:distribution'
const VOLUME_SHARE = 'profile:plan:volume:share'
const VOLUME_REQUEST = 'profile:plan:volume:request'
const SHARE_STATISTICS = 'profile:plan:share:statistics'

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'PLAN_LIST',
      },
    }),
    route(':id/detail/', Detail, DETAIL, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'PLAN_EDIT',
      },
      props: true,
    }),
    route('matrix/', Matrix, MATRIX, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'PLAN_MATRIX',
      },
    }),
    route('volume/distribution/', VolumeDistribution, VOLUME_DISTIBUTION, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'PLAN_VOLUME_DISTIBUTION',
      },
    }),
    route('volume/share/', VolumeShare, VOLUME_SHARE, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'PLAN_VOLUME_SHARE',
      },
    }),
    route('volume/request/', VolumeRequest, VOLUME_REQUEST, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'PLAN_VOLUME_REQUEST',
      },
    }),
    route('share/statistics/', ShareStatistics, SHARE_STATISTICS, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'PLAN_SHARE_STATISTICS',
      },
    }),
  ]
}
