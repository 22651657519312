<template lang='pug'>
  include /mixins
  +b.i-filter
    +e.button
      +b.BUTTON.control-button(@click.prevent="openFilter")
        +e.element
          +e.SPAN.text {{ _("Фильтр") }}
    +e.view(:class="{'is-active': isActive}")
      +b.blur(
        @click.prevent="hideFilter"
        :class="{'is-hidden': !isActive}"
      )
      +e.wrapper
        +e.main
          +b.i-preloader.--variant_1.--absolute(:class="{'is-active': loading}")
            +e.item.--size_md
          +e.content
            +b.i-panel
              filter-ui(
                ref="filterUi"
                :disabled="loading"
                :value="prepared"
                :filters="filters"
                :pagination='pagination'
                :additional-resource="additionalResource"
                :label="label"
                @input="handleInputFilter"
              )
                template(#additional)
                  slot(name="additional")
              +b.ds-panel.--space_xl
                +e.element.--offset_top
                  +b.BUTTON.control-button.--size_sm(@click.prevent='handleInputFilter({}, "clear")')
                    +e.element
                      +e.SPAN.text {{ _("Очистить фильтр") }}
      +e.footer
        +b.BUTTON.control-button(@click.prevent="hideFilter")
          +e.element
            +e.SPAN.text {{ _("Применить") }}
</template>

<script>

import ControllerMixin from './mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}

</script>
