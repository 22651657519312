export const toggleOverflow = isActive => {
  const element = document.querySelector('html')
  const { pageYOffset } = window

  if (isActive) {
    element.style.top = `-${pageYOffset}px`
    element.classList.add('is-overflow')
  } else {
    const scrollOffset = Math.abs(element.offsetTop)

    element.removeAttribute('style')
    element.classList.remove('is-overflow')

    window.scrollTo({
      top: scrollOffset,
      behavior: 'auto',
    })
  }
}
