export function createStore() {
  return {
    namespaced: true,

    state: {
      pageTitle: {
        content: '',
        classes: '',
      },
    },

    mutations: {
      CHANGE_PAGE_TITLE_POSTFIX(state, result) {
        /* eslint-disable-next-line */
        if (typeof result === 'string') {
          state.pageTitle = { content: result }
        } else {
          state.pageTitle = result
        }
      },
    },
  }
}
