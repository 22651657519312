import moment from 'moment'
import { CURRENT_LANGUAGE } from '@utils/urls'
import { prettifyPhone } from '@utils/transformers'

moment.locale(CURRENT_LANGUAGE)

export const prettifyNumber = p => (p).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')

export const install = Vue => {
  Vue.filter('prettifyPhone', prettifyPhone)
  Vue.filter('prettifyNumber', prettifyNumber)
  Vue.filter('formatMomentDate', value => moment(value).format('DD.MM.YYYY'))
  Vue.filter('formatMomentDateTime', value => moment(value).format('DD.MM.YYYY HH:mm'))
  Vue.filter('formatMonth', value => moment(value, 'M').format('MMMM'))
  Vue.filter('formatDecimal', a => {
    const preparedValue = Number(a)
    const numberWithPoint = parseFloat(a).toFixed(2)

    return Number.isInteger(preparedValue) ? preparedValue : numberWithPoint
  })
}

export default { install }
