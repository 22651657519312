<template>
  <filter-controller
    @input="handleFilter"
    :receive="allProps.receive"
    :all="allProps.result"
    :pagination='pagination'
    :loading="allProps.loading"
    :value="value"
    :label="label"
    :filterKey="filterKey"
    :defaultFilterParams="defaultFilterParams"
  >
    <template #additional>
      <slot name="additional" />
    </template>
  </filter-controller>
</template>

<script>

import ViewMixin from './mixins/ViewMixin'

export default {
  mixins: [ViewMixin],
}

</script>
