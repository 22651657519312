import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const AgreedVolumeCreate = () => import('./views/AgreedVolume/Create')
const CheckoutController = () => import('./components/CheckoutController')
const ExtraVolumeCreate = () => import('./views/ExtraVolume/Create')
const SafekeepingCreate = () => import('./views/Safekeeping/Create')
const SafekeepingRemove = () => import('./views/Safekeeping/Remove')
const Success = () => import('./views/Success')

const LIST = 'profile:order:list'
const AGREED_VOLUME_CREATE = 'profile:order:agreed:volume'
const AGREED_VOLUME_CHECKOUT = 'profile:order:agreed:volume:checkout'
const EXTRA_VOLUME_CREATE = 'profile:order:extra:volume'
const EXTRA_VOLUME_CHECKOUT = 'profile:order:extra:volume:checkout'
const SAFEKEEPING_CREATE = 'profile:order:safekeeping'
const SAFEKEEPING_REMOVE = 'profile:order:safekeeping:remove'
const SUCCESS = 'profile:order:success'

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'ORDER_LIST',
      },
    }),
    route('agreed/volume/', AgreedVolumeCreate, AGREED_VOLUME_CREATE, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'ORDER_AGREED_VOLUME',
      },
    }),
    route('agreed/volume/checkout/', CheckoutController, AGREED_VOLUME_CHECKOUT, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'ORDER_AGREED_VOLUME_CHECKOUT',
      },
    }),
    route('extra/volume/', ExtraVolumeCreate, EXTRA_VOLUME_CREATE, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'ORDER_EXTRA_VOLUME',
      },
    }),
    route('extra/volume/checkout/', CheckoutController, EXTRA_VOLUME_CHECKOUT, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'ORDER_EXTRA_VOLUME_CHECKOUT',
      },
    }),
    route('safekeeping/', SafekeepingCreate, SAFEKEEPING_CREATE, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'ORDER_SAFEKEEPING',
      },
    }),
    route('safekeeping/remove/', SafekeepingRemove, SAFEKEEPING_REMOVE, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'ORDER_SAFEKEEPING_REMOVE',
      },
    }),
    route('success/', Success, SUCCESS, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'ORDER_SUCCESS',
      },
    }),
  ]
}
