<script>
import { debounce } from 'lodash'

export default {
  data() {
    return {
      highlightedRow: null,
      debounceFn: () => {},
    }
  },

  mounted() {
    const timeout = 20

    this.debounceHighlightRow = debounce(this.highlightRow, timeout)
    this.debounceRemoveHighlightRow = debounce(this.removeHighlightRow, timeout)
  },

  methods: {
    highlightRow(index) {
      this.highlightedRow = index
    },

    removeHighlightRow() {
      this.highlightedRow = null
    },
  },

  render() {
    return this.$scopedSlots.default({
      highlightRow: this.debounceHighlightRow,
      removeHighlightRow: this.debounceRemoveHighlightRow,
      highlightedRowIndex: this.highlightedRow,
    })
  },
}
</script>
