<template>
  <filter-controller
    :filters="filters"
    :receive="allProps.receive"
    :all="allProps.result"
    :loading="allProps.loading"
    :allAdditional="additionalProps.result"
    :additionalResource="additionalResource"
    :pagination='pagination'
    :totalItems="totalItems"
    :value="value"
    :label="label"
    @input="handleFilter"
  >
    <template #additional>
      <slot name="additional" />
    </template>
  </filter-controller>
</template>

<script>
import ViewMixin from './mixins/ViewMixin'

export default {
  mixins: [ViewMixin],
}
</script>
