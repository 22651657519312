<template lang="pug">
  include /mixins
  +b.i-avatar
    +e.IMG.image(
      v-if="avatar"
      :src="avatar"
    )
    +e.icon(v-else)
      +b.I.icon-user
</template>

<script>
export default {
  props: {
    avatar: {},
  },
}
</script>
