import { snakelize } from '@utils/case'
import { WIDGETS_MAP } from '../widgets'

export default {
  widgetsMap: WIDGETS_MAP,

  methods: {
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },

    updateAutocompleteValue(filter, value) {
      const { autocompleteSettings: { valuePath }, name } = filter

      this.$emit('input', { ...this.value, [name]: value[valuePath] })
    },

    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },

    updateMultipleValues(key, values) {
      const res = {}
      const [min, max] = values
      const snakelizedKey = snakelize(key)
      const fromKey = `${snakelizedKey}_from`
      const toKey = `${snakelizedKey}_to`

      if (min) {
        res[fromKey] = min
      } else {
        delete this.value[fromKey]
      }

      if (max) {
        res[toKey] = max
      } else {
        delete this.value[toKey]
      }

      this.$emit('input', { ...this.value, ...res })
    },
  },
}
