import PaginatedResourceController from './PaginatedResourceController';
import ResourceLoaderController from './ResourceLoaderController';
import PaginationController from './PaginationController';
import DelayedExecutorController from './DelayedExecutorController';

function install(Vue) {
  Vue.component(PaginatedResourceController.name, PaginatedResourceController);
  Vue.component(ResourceLoaderController.name, ResourceLoaderController);
  Vue.component(PaginationController.name, PaginationController);
  Vue.component(DelayedExecutorController.name, DelayedExecutorController);
}

export default {
  install,
};
