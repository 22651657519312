<template>
  <select-widget
    v-bind="$attrs"
    v-on="$listeners"
    ref="filterWidget"
    :multiple="multiple"
  />
</template>

<script>

import SelectWidget from './SelectWidget'

export default {
  name: 'filter-multiselect-widget',
  components: { SelectWidget },

  props: {
    multiple: { type: Boolean, default: true },
  },

  methods: {
    clearValue() {
      this.$refs.filterWidget.clearValue()
    },
  },
}

</script>
