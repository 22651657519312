<template lang="pug">
  include /mixins
  +b.field-wrapper
    +e.icon-wrapper(v-if="filter.icon")
      +e.IMG.img(:src="filter.icon")
    +e.content(:class="{ 'field-wrapper__content--offset_left': filter.icon }")
      d-control-multiselect(
        v-model='checked'
        :multiple='multiple',
        :options='filter.items || []'
        :name='filterName'
        :label="filterLabel"
        :disabled="disabled"
        :searchable="searchable || filter.searchable"
        :close-on-select="closeOnSelect"
        :maxHeight="224"
        :input-label="filter.title"
        :placeholder="filter.placeholder || defaultPlaceholder"
        track-by='slug'
        @input='submit'
      )
        template(slot="noResult" slot-scope="{}")
          span {{ _("Элементы не найдены") }}
        template(slot="noOptions" slot-scope="{}")
          span {{ _("Список пуст") }}
        template(
          slot="selection"
          slot-scope="{ values, search }"
          v-if="values.length"
        )
          +b.SPAN.multiselect__single
            | {{ values.map(el => el.label).join(', ') }}
          +b.i-count.--variant_2
            +e.text {{ values.length }}
        template(slot="option", slot-scope="{ option }")
          span(:class="option.level ? 'multiselect__child multiselect__child--'+option.level : ''") {{ option.label }}
</template>

<script>
export default {
  name: 'SelectWidget',

  props: {
    value: {},
    filter: {},
    filterName: {},
    disabled: {
      default: false,
    },
    filterLabel: {
      default: 'label',
    },
    searchable: {
      default: false,
    },
    multiple: {
      default: false,
    },
    closeOnSelect: {
      default: true,
    },
  },

  data() {
    return {
      checked: '',
      defaultPlaceholder: this._('Все'),
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },

  methods: {
    submit() {
      const val = this.normalizeTo(this.checked)
      this.$emit('input', val)
    },

    normalizeTo(value) {
      const val = this.getValue(value)

      const checked = this.filter.items
        .filter(el => val.find(v => v.slug === el.slug))
        .map(el => el.slug)
      return checked
    },

    normalizeFrom(value) {
      const val = this.getValue(value)

      const checked = this.filter.items
        .filter(el => val.find(v => v.toString() === el.slug.toString()))
      return checked
    },

    getValue(value) {
      let val = value

      if (!value) return []

      if (!Array.isArray(value)) {
        val = [value]
      }

      return val
    },

    clearValue() {
      this.checked = null
    },
  },
}
</script>
