import debounce from 'lodash.debounce'

export default {
  name: 'delayed-executor-controller',

  props: {
    delay: {
      type: Number,
      default: 0,
    },
    receiver: {
      type: Function,
      required: true,
    },
  },

  computed: {
    receive() {
      if (0 === this.delay) {
        return this.receiver;
      }

      return debounce(this.receiver, this.delay);
    },
  },

  render() {
    return this.$scopedSlots.default({
      receive: this.receive,
    })
  },
};
