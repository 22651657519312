<template lang="pug">
  include /mixins
  +b.LABEL.d-control-input__label(:for='id')
    +b.g-row.--space_xs.--align_center
      +b.g-cell
        span {{ inputLabel }}
        span(v-if='isRequired') *
      +b.g-cell(v-if="hint")
        ui-tooltip(:hint="hint")
</template>

<script>
export default {
  name: 'StaticLabel',

  props: {
    id: {
      type: String,
      required: false,
    },
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
  },
}
</script>
