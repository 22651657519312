<template lang="pug">
  include /mixins
  +b.d-control-input__icon.--append(
    @click.prevent='appendIconFunc(appendIconData || $event)',
    :class="appendIcon"
  )
    slot(name="appendIcon")
</template>
<script>
export default {
  name: 'AppendComponent',
  props: {
    appendIcon: {},
    appendIconFunc: {
      type: Function,
      default: () => {},
    },
    appendIconData: {},
  },
}
</script>
