<template lang="pug">
  include /mixins.pug
  validation-provider(
    slim
    v-slot="{ errors }"
    :name="name"
  )
    +b.avatar-wrapper(:class="wrapperClass")
      +e.item
        u-avatar(
          :avatar="value"
          :class="avatarClass"
        )
        +e.close(
          v-if="value && allowUpload"
          @click.prevent="clear"
        )
          +b.I.icon-close
      +e.button(v-if="allowUpload")
        ui-cropper(@image:crop="update")
          template(slot="button")
            +b.control-button.--space_2.--variant_1
              +e.element
                +e.icon
                  +b.I.icon-download
                +e.SPAN.text {{ buttonText || _("Завантажити логотип") }}
    +b.P.error-label(v-if="errors[0]") {{ errors[0] }}
</template>

<script>
export default {
  props: {
    value: {},
    name: {
      type: String,
    },
    avatarClass: {
      type: String,
      default: 'i-avatar--size_xl',
    },
    wrapperClass: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    allowUpload: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    update(data) {
      this.$emit('input', data)
    },

    clear() {
      this.$emit('input', null)
    },
  },
}
</script>
