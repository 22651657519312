<template lang="pug">
  include /mixins.pug
  div(
    :class="{ 'is-active': value[name] }"
    @click.prevent="changeSort"
  )
    +b.g-row.--space_sm.--appearance_nowrap.--align_center
      +b.g-cell
        slot
      +b.g-cell
        +b.g-row.--space_sm.--align_center.--appearance_nowrap
          +b.g-cell
            +b.g-row.--appearance_column
              +b.g-cell
                +b.ds-inliner.--size_sm(:class="value[name] === 'asc' ? 'ds-inliner--color_blue' : 'ds-inliner--color_light-blue'")
                  +e.body
                    +b.I.icon-chevron-up
              +b.g-cell
                +b.ds-inliner.--size_sm(:class="value[name] === 'desc' ? 'ds-inliner--color_blue' : 'ds-inliner--color_light-blue'")
                  +e.body
                    +b.I.icon-chevron-down
          +b.g-cell(v-if="value[name]")
            +b.ds-inliner.--size_xxs.--color_blue(@click.stop="resetSort")
              +e.body
                +b.I.icon-close
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
    },
    name: {
      type: String,
    },
  },

  methods: {
    changeSort() {
      if ('asc' === this.value[this.name]) {
        this.$emit('input', { [this.name]: 'desc' })
      } else if ('desc' === this.value[this.name]) {
        this.$emit('input', { [this.name]: 'asc' })
      } else {
        this.$emit('input', { [this.name]: 'desc' })
      }
    },

    resetSort() {
      this.$emit('input', { [this.name]: null })
    },
  },
}
</script>
