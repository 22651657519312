import Plugin from './components'
import { TYPES } from './constances'

function install(Vue) {
  Vue.use(Plugin)

  Vue.prototype.$allRoles = TYPES
}

export default { install }
