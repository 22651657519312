import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const CreateController = () => import('./views/CreateController')
const Create = () => import('./views/Create')
const List = () => import('./views/List')
const Display = () => import('./views/Display')
const AuthorizationData = () => import('./views/AuthorizationData')
const CompanyList = () => import('../company/views/List')
const CompanyEdit = () => import('../company/views/Edit')
const CompanyCreate = () => import('../company/views/Create')
const CompanySearch = () => import('../company/views/Search')
const CompanyAdd = () => import('../company/views/Add')
const ManagerList = () => import('../manager/views/List')
const ManagerCreate = () => import('../manager/views/Create')
const ManagerDisplay = () => import('../manager/views/Display')
const WarehouseList = () => import('../warehouse/views/List')
const WarehouseCreate = () => import('../warehouse/views/Create')
const WarehouseDisplay = () => import('../warehouse/views/Display')

const CREATE = 'profile:distributor:create'
const COMPANY_SEARCH = 'profile:distributor:company:search'
const COMPANY_ADD = 'profile:distributor:company:add:search'
const COMPANY_ADD_CREATE = 'profile:distributor:company:add:create'
const COMPANY_CREATE = 'profile:distributor:company:create'
const LIST = 'profile:distributor:list'
const COMPANY_LIST = 'profile:distributor:company:list'
const COMPANY_EDIT = 'profile:distributor:company:edit'
const AUTHORIZATION_DATA = 'profile:distributor:authorization'
const MANAGER_LIST = 'profile:distributor:manager:list'
const MANAGER_CREATE = 'profile:distributor:manager:create'
const MANAGER_DISPLAY = 'profile:distributor:manager:edit'
const WAREHOUSE_LIST = 'profile:distributor:warehouse:list'
const WAREHOUSE_CREATE = 'profile:distributor:warehouse:create'
const WAREHOUSE_DISPLAY = 'profile:distributor:warehouse:edit'

export function createRoutes(options) {
  return [
    route('', CreateController, null, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        entity: 'distributor',
        additionalPermissions: ['ADD', 'VIEW', 'CHANGE'],
      },
      children: [
        route('create/', Create, CREATE, {
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_ADD',
            steps: ['common'],
            entity: 'distributor',
            additionalPermissions: ['ADD'],
          },
        }),
        route('company/search/', CompanySearch, COMPANY_SEARCH, {
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_COMPANY_ADD',
            steps: ['common', 'company'],
            entity: 'company',
            additionalPermissions: ['ADD'],
          },
        }),
        route('company/info/', CompanyCreate, COMPANY_CREATE, {
          props: {
            default: true,
            prevRoute: 'profile:distributor:company:search',
          },
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_COMPANY_ADD',
            steps: ['common', 'company'],
            entity: 'company',
            additionalPermissions: ['ADD'],
          },
        }),
      ],
    }),
    route('company/add/search/', CompanyAdd, COMPANY_ADD, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'DISTRIBUTOR_COMPANY_ADD',
        entity: 'company',
        additionalPermissions: ['ADD'],
      },
    }),
    route('company/add/create/', CompanyCreate, COMPANY_ADD_CREATE, {
      props: {
        default: true,
        prevRoute: 'profile:distributor:company:list',
      },
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'DISTRIBUTOR_COMPANY_ADD',
        entity: 'company',
        additionalPermissions: ['ADD'],
      },
    }),
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        title: 'DISTRIBUTOR_LIST',
        entity: 'distributor',
        additionalPermissions: ['VIEW'],
      },
    }),
    route(':distributorId/', Display, null, {
      props: true,
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER], options),
        entity: 'distributor',
        additionalPermissions: ['VIEW'],
      },
      children: [
        route('company/list/', CompanyList, COMPANY_LIST, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_COMPANY_LIST',
            tabsIsVisible: true,
            entity: 'company',
            additionalPermissions: ['VIEW'],
            fallbackRouteName: LIST,
          },
        }),
        route('company/:id/edit/', CompanyEdit, COMPANY_EDIT, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: '',
            entity: 'company',
            additionalPermissions: ['VIEW'],
            fallbackRouteName: COMPANY_LIST,
          },
        }),
        route('authorization/', AuthorizationData, AUTHORIZATION_DATA, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_AUTHORIZATION_DATA',
            tabsIsVisible: true,
            fallbackRouteName: LIST,
          },
        }),
        route('warehouse/list/', WarehouseList, WAREHOUSE_LIST, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_WAREHOUSE_LIST',
            tabsIsVisible: true,
            entity: 'warehouse',
            additionalPermissions: ['VIEW'],
            fallbackRouteName: LIST,
          },
        }),
        route('warehouse/create/', WarehouseCreate, WAREHOUSE_CREATE, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_WAREHOUSE_ADD',
            entity: 'warehouse',
            additionalPermissions: ['ADD'],
            fallbackRouteName: WAREHOUSE_LIST,
          },
        }),
        route('warehouse/:id/edit/', WarehouseDisplay, WAREHOUSE_DISPLAY, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_WAREHOUSE_EDIT',
            entity: 'warehouse',
            additionalPermissions: ['CHANGE'],
            fallbackRouteName: WAREHOUSE_LIST,
          },
        }),
        route('manager/list/', ManagerList, MANAGER_LIST, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_MANAGER_LIST',
            tabsIsVisible: true,
            entity: 'distributormanager',
            additionalPermissions: ['VIEW'],
            fallbackRouteName: LIST,
          },
        }),
        route('manager/create/', ManagerCreate, MANAGER_CREATE, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_MANAGER_ADD',
            entity: 'distributormanager',
            additionalPermissions: ['ADD'],
            fallbackRouteName: MANAGER_LIST,
          },
        }),
        route('manager/:id/edit/', ManagerDisplay, MANAGER_DISPLAY, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER], options),
            title: 'DISTRIBUTOR_MANAGER_EDIT',
            entity: 'distributormanager',
            additionalPermissions: ['CHANGE'],
            fallbackRouteName: MANAGER_LIST,
          },
        }),
        {
          path: '*',
          redirect: { name: COMPANY_LIST },
        },
      ],
    }),
  ]
}
