const Dropdown = () => import('./Dropdown')
const ModalTrigger = () => import('./ModalTrigger')
const ClickOutside = () => import('./ClickOutside')
const Toggle = () => import('./Toggle')
const Cropper = () => import('./Cropper')
const Tabs = () => import('./Tabs')
const Uploader = () => import('./Uploader')
const Switch = () => import('./Switch')
const Tooltip = () => import('./Tooltip')
const ColorPicker = () => import('./ColorPicker')
const Percentage = () => import('./Percentage')
const ScrollTopButton = () => import('./ScrollTopButton')

export default function install(Vue) {
  Vue.component('ui-dropdown', Dropdown)
  Vue.component('ui-modal-trigger', ModalTrigger)
  Vue.component('ui-click-outside', ClickOutside)
  Vue.component('ui-toggle', Toggle)
  Vue.component('ui-cropper', Cropper)
  Vue.component('ui-tabs', Tabs)
  Vue.component('ui-uploader', Uploader)
  Vue.component('ui-switch', Switch)
  Vue.component('ui-tooltip', Tooltip)
  Vue.component('ui-color-picker', ColorPicker)
  Vue.component('ui-percentage', Percentage)
  Vue.component('ui-scroll-top-button', ScrollTopButton)
}
