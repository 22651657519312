<script>
const zero = 0

export default {
  beforeUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  },

  mounted() {
    document.addEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      this.calcTableHeadTopOffset()
    },

    calcTableHeadTopOffset() {
      const content = this.$el
      const parentHeads = Array.from(content.querySelectorAll('thead')).filter(el => !el.closest('tbody'))
      const heads = [
        ...parentHeads,
        ...content.querySelectorAll('.js-table-head'),
      ]

      if (!heads) return

      heads.forEach(el => {
        const offsetTop = content.getBoundingClientRect().top

        if (zero >= offsetTop) {
          el.style.top = `${Math.abs(offsetTop)}px`
        } else {
          el.style.top = 0
        }
      })
    },
  },

  render() {
    return this.$slots.default[0]
  },
}
</script>
