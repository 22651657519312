import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal'
import VueBem from '@aspectus/vue-bem'
import VueGrid from '@aspectus/vue-grid'
import Tag from '@aspectus/vue-tag'
import VueDermis from '@aspectus/vue-dermis'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import ResourceComponents from '@resource'
import VuePermissions from '@aspectus/vue-permissions'
import Users from '@md/users'
import Notifications from 'vue-notification'
import VueTable from '@aspectus/vue-table'
import { VTooltip } from 'v-tooltip'
import { ObserveVisibility } from 'vue-observe-visibility'
import { IS_DESKTOP } from '@utils/constances'
import { Sketch } from 'vue-color'
import VueClipboard from 'vue-clipboard2'
import VueAllSelectionController from '@aspectus/vue-all-selection-controller'
import VueSelectionController from '@aspectus/vue-selection-controller'
import VueMeta from 'vue-meta'

const VueInputMask = require('vue-inputmask').default

export function install(Vue) {
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(VueI18n)
  Vue.use(VueGrid)
  Vue.use(Tag)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      clickToClose: IS_DESKTOP,
      height: 'auto',
      transition: IS_DESKTOP ? 'nice-modal-fade' : false,
      scrollable: true,
    },
  })
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(VueInputMask)
  Vue.use(ResourceComponents)
  Vue.use(VueDermis)
  Vue.use(VuePermissions)
  Vue.use(Users)
  Vue.use(Notifications)
  Vue.use(VueTable)
  Vue.use(VueClipboard)
  Vue.use(VueAllSelectionController)
  Vue.use(VueSelectionController)
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
  })

  Vue.directive('tooltip', VTooltip)
  Vue.directive('observe-visibility', ObserveVisibility)

  Vue.component('sketch-picker', Sketch)
}

export default { install }
