<template lang="pug">
  include /mixins.pug
  div
    +b.g-row.--space_xs.--appearance_spaced
      +b.g-cell(v-for="attr in choices")
        +b.LABEL.d-control-checkbox.--variant_1(
          :style="{ '--checker-color': attr.colorRgb }"
        )
          +e.INPUT.element(
            name="color"
            type='radio'
            :value="attr.id"
            v-model='checked'
            @change="$emit('input', checked)"
          )
          +e.label.is-visible-xl(
            v-tooltip.auto="{ content: attr.color }"
          )
          +e.label.is-hidden-xl
</template>

<script>
export default {
  name: 'ColorWidget',

  props: {
    choices: {},
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.checked = nval
      },
    },
  },

  data() {
    return {
      checked: null,
    }
  },
}
</script>
