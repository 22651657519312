import Vue from 'vue'

Vue.directive('table-wrapper', {
  bind: node => {
    const tables = Array.from(node.querySelectorAll('table'))
    if (tables.length) {
      tables.forEach(el => {
        const wrapper = document.createElement('div')
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)
        wrapper.classList.add('table-wrapper')
      })
    }
  },
})
