<template lang="pug">
  include /mixins.pug
  +b.relative
    d-control-multiselect(
      v-model="formdata.plan"
      :allow-empty="false"
      :options="plans"
      name="plan"
      label="name",
      @input="$emit('select')"
    )
    +b.i-count.--variant_3
      ui-tooltip(
        :hint="hint"
        :classes="['tooltip--variant_2']"
      )
</template>

<script>
export default {
  props: {
    plans: {},
    formdata: {},
  },

  data() {
    return {
      hint: this._('Дані за цим планом відображатимуться в інформаційній панелі та при оформленні заявок'),
    }
  },
}
</script>
