import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const CurrentPerfomance = () => import('./views/current/Perfomance')
const NextPlanning = () => import('./views/next/Planning')
const Controller = () => import('./views/Controller')

const CURRENT_PERFOMANCE = 'profile:season:current:perfomance'
const NEXT_PLANNING = 'profile:season:next:planning'

export function createRoutes(options) {
  return [
    route('', Controller, null, {
      children: [
        route('current/perfomance/', CurrentPerfomance, CURRENT_PERFOMANCE, {
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
            title: 'PLAN_CURRENT_PERFOMANCE',
          },
        }),
        route('next/planning/', NextPlanning, NEXT_PLANNING, {
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
            title: 'PLAN_NEXT_PLANNING',
          },
        }),
      ],
    }),
  ]
}
