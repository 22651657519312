import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Controller = () => import('./views/Controller')
const Detail = () => import('./views/Detail')
const Certificates = () => import('./views/Certificates')

const LIST = 'profile:product:list'
const DETAIL = 'profile:product:detail'
const CERTIFICATE_LIST = 'profile:product:certificate:list'

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
        title: 'PRODUCT_LIST',
        entity: 'product',
        additionalPermissions: ['VIEW'],
      },
    }),
    route(':id/', Controller, null, {
      props: true,
      children: [
        route('detail/', Detail, DETAIL, {
          props: true,
          entity: 'product',
          additionalPermissions: ['VIEW'],
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
            title: 'PRODUCT_DETAIL',
            fallbackRouteName: LIST,
          },
        }),
        route('certificate/list/', Certificates, CERTIFICATE_LIST, {
          props: true,
          entity: 'product',
          additionalPermissions: ['VIEW'],
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER, TYPES.DISTRIBUTOR, TYPES.DISTRIBUTOR_MANAGER], options),
            title: 'PRODUCT_CERTIFICATE_LIST',
            fallbackRouteName: LIST,
          },
        }),
      ],
    }),
  ]
}
