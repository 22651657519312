<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced
    +b.g-cell.g-cols.--12-xs(v-for="filter in filters")
      +b.P.ds-caption.--size_xs {{ filter.title }}
      +b.ds-panel.--space_xs
        +e.element.--offset_top
          tag(
            :tag="$options.widgetsMap[filter.type]"
            :value="preparedValues[filter.name]"
            :filter-name="filter.name"
            :filter="filter"
            @input="'range' === filter.type ? updateMultipleValues(filter.name, $event) : updateValue(filter.name, $event)"
          )
</template>

<script>
import UiMixin from './mixins/UiMixin'
import PrepareValuesMixin from './mixins/PrepareValuesMixin'

export default {
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'additionalResource',
    'label',
  ],

  mixins: [
    UiMixin,
    PrepareValuesMixin,
  ],
}
</script>
