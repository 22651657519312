import { renderSlim } from '@aspectus/vue-utils'
import { Tag } from '@aspectus/vue-tag'

export default {
  props: {
    permissions: {
      type: Array,
    },
    entity: {
      type: String,
    },
  },

  data() {
    return {
      hasAccess: false,
    }
  },

  created() {
    this.checkAccess()
  },

  methods: {
    checkAccess() {
      const entity = window.permissions.entities.find(p => p.entity === this.entity)
      const activePermissions = entity.permissions.filter(p => p.has).map(p => p.name)
      const hasAccess = this.permissions.some(p => activePermissions.includes(p))

      this.hasAccess = hasAccess
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      hasAccess: this.hasAccess,
    }) || [], h, Tag)
  },
}
