import { isEmpty } from 'lodash'
import isShallowEqual from '@wordpress/is-shallow-equal'
import { toggleOverflow } from '@utils/toggleOverflow'
import FilterUi from '../Ui'

export default {
  props: [
    'loading',
    'value',
    'receive',
    'all',
    'available',
    'pagination',
    'label',
    'filterKey',
    'defaultFilterParams',
    'isLocalFilters',
  ],

  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
      isActive: false,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }

        this.prepare(filters)
      },
    },
  },

  computed: {
    totalCount() {
      if (this.pagination && this.pagination.total) {
        return this.pagination.total
      }

      if (this.value && this.value.total) {
        return this.value.total
      }

      return 0
    },

    filters() {
      const initialFilters = (this.all && this.all.item) ? this.all.item : []
      const filteredItems = initialFilters.filter(item => 'order' !== item.name)
      const preparedFitlers = filteredItems.map(item => {
        if ('verification_status' === item.name) {
          item.items = item.items.map(el => {
            el.slug = el.slug.toString()

            return el
          })
        }

        return item
      })

      return preparedFitlers
    },

    searchFilter() {
      return this.filters.find(item => 'input' === item.type)
    },
  },

  created() {
    if (this.filterKey) {
      const params = { preset_key: this.filterKey }

      if (this.defaultFilterParams && this.defaultFilterParams.distributor) {
        params.distributor = this.defaultFilterParams.distributor
      }

      this.receive(params)
    }
  },

  methods: {
    prepare(value) {
      this.prepared = value
    },

    updateValue(key, value) {
      const filters = { ...this.prepared, [key]: value }

      this.handleInputFilter(filters)
    },

    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },

    handleInput(value) {
      this.prepare(value)
    },

    filter() {
      if (isEmpty(this.prepared) && this.isLocalFilters) {
        this.$emit('reset')

        this.$refs.filterUi.clearWidgetValue()
      }

      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 })
    },

    openFilter() {
      this.isActive = true

      toggleOverflow(this.isActive)
    },

    hideFilter() {
      this.isActive = false

      toggleOverflow(this.isActive)
    },
  },
}
