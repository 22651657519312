<template lang="pug">
  include /mixins
  d-control-multiselect(
    v-model='checked'
    :options='choices'
    :name='filterName'
    label='label'
    :searchable="false"
    :close-on-select="true"
    :maxHeight="224"
    :input-label="filter.title"
    :placeholder="defaultPlaceholder"
    @input='submit'
  )
</template>

<script>
export default {
  name: 'SelectWidget',

  props: {
    value: {},
    filter: {},
    filterName: {},
    filterLabel: {
      default: 'label',
    },
    multiple: {
      default: false,
    },
  },

  data() {
    return {
      checked: '',
      defaultPlaceholder: this._('Все'),
      choices: [
        {
          label: this._('Да'),
          slug: 'true',
        },
        {
          label: this._('Нет'),
          slug: 'false',
        },
      ],
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },

  methods: {
    submit() {
      const val = this.normalizeTo(this.checked)
      this.$emit('input', val)
    },

    normalizeTo(value) {
      const val = this.getValue(value)

      const checked = this.choices
        .filter(el => val.find(v => v.slug === el.slug))
        .map(el => el.slug)
      return checked
    },

    normalizeFrom(value) {
      const val = this.getValue(value)

      const checked = this.choices
        .filter(el => val.find(v => v === el.slug))
      return checked
    },

    getValue(value) {
      let val = value

      if (!value) return []

      if (!Array.isArray(value)) {
        val = [value]
      }

      return val
    },
  },
}
</script>
